import React, { useState, useEffect,useCallback } from "react";
import useStore from "../../store";
import { updateAdvert } from "../../api";
import { debounce } from 'lodash';
import { FormControlLabel, MenuItem, Stack, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { Controller, useFormContext } from "react-hook-form";
import { defaultFeatures, featuresAndAmenities, stages as stage } from "../../utilis/helper";
import FeatureCheckboxGroup from "../FeatureCheckboxGroup";

export const FeatFeatures = ({handleFeatures}) => {
  const { register, control, setValue } = useFormContext();
  const stages = stage;
  const [loading, setLoading] = useState(true);
  const advert = useStore((state) => state.advertDetails);
  const token = useStore((state) => state.jwtToken);
  const advertId = useStore(state => state.advertId);
  const features = useStore((state) => state.features);
  const setFeatures = useStore((state) => state.setAdvertFeatures);
  const updateFeature = useStore((state) => state.updateFeature);
  const updateAdvertDetails = useStore((state) => state.updateAdvertDetails);

  useEffect(() => {
    setLoading(false);
  }, [advert.featuresAndAmenities, setFeatures]);

  const handleUpdate = useCallback((token, advertId, key, value) => {
    if( key === "stage") {

      return updateAdvert(token, { id: advertId, key, value: value.toString() })
    }

    if(key === "buildYear" | key === "renovation") {
      return updateAdvert(token, { id: advertId, key, value: parseInt(value) })
    }
    return updateAdvert(token, { id: advertId, key, value });
  }, []);

  const debouncedUpdateAdvert = useCallback(
    debounce((token, advertId, key, value) => {
     
      handleUpdate(token, advertId, key, value);
    }, 1000), // Adjust the delay as needed
    [handleUpdate]
  );

  const handleChangeOverAll = (key) => async(event) => {

    const value = event.target.value;
    // Update the form value
    setValue(key, value);
    
    updateAdvertDetails(key, value);

    debouncedUpdateAdvert(token, advertId,key, value)
  };
  

  

  const handleChange = (_, feature) => (e) => {
    const newValue = e.target.checked; 
  
    updateFeature(feature.label, newValue);
    const values = advert.featuresAndAmenities;

    const updatedFeatures = features.map((f) =>
    f.label === feature.label ? { ...f, checked: newValue } : f);


    const key = "featuresAndAmenities";
    const value = updatedFeatures;
    
    debouncedUpdateAdvert(token, advertId,key, value)

  }



  return (
    <>

      <h2 className="text-2xl sm:text-3xl  font-bold text-left mb-5">
        Details
      </h2>
      <Stack
        direction={{ xs: "colum", md: "row" }}
        spacing={3}
        width="100%"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("stage")}
          onChange={handleChangeOverAll("stage")}
          type="text"
          select
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "49.2%" },
          }}
          id="outlined-required"
          label="Etage"
        >
          {stages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          required
          {...register("buildYear")}
          onChange={handleChangeOverAll("buildYear")}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputProps={{
            endAdornment: <span>Jahr</span>,
          }}
          id="outlined-required"
          type="number"
          label="Baujahr"
          helperText="Jahr eingeben in JJJJ - Format"
        ></TextField>

        <TextField
          required
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputProps={{
            endAdornment: <span>Jahr</span>,
          }}
          {...register("renovation")}
          onChange={handleChangeOverAll("renovation")}
          id="outlined-required"
          type="number"
          label="Letzte Renovation"
          helperText="Jahr eingeben in JJJJ - Format"
        ></TextField>
      </Stack>
      <div className="mt-10">
        <h2 className="text-2xl sm:text-3xl  font-bold text-left mb-5">
          Merkmale & Austattungen
        </h2>
      </div>
      <Box sx={{ marginBottom: "40px", display: 'flex' }}>
      {loading ? (
          <CircularProgress />
        ) : (
          <FeatureCheckboxGroup
            features={advert.featuresAndAmenities}
            handleChange={handleChange}
          />
        )}
      </Box>
    </>
  );
};

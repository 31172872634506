import React, {useState, useEffect, useCallback, useRef } from "react";
import useStore from "../../store";
import { updateAdvert, updateAdvertTwo, updateAdvertMultiFields } from "../../api";
import {objectType, customerSegment, convertIsoDate} from '../../utilis/helper';
import { debounce } from 'lodash';
import {useRequestQueue} from '../../utilis/queue';

import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Sheet from '@mui/joy/Sheet';
import {APIProvider, Map, useMapsLibrary, Marker} from '@vis.gl/react-google-maps';
import FormLabel from '@mui/joy/FormLabel';
import { Radio as RadioJoy, RadioGroup as RadioGroupJoy} from '@mui/joy';
import { radioClasses } from '@mui/joy/Radio';
import Box from '@mui/joy/Box';
import Tooltip from "@mui/material/Tooltip";
import { useFormContext } from "react-hook-form";
import { resultArray as resultArrayData } from "../../utilis/helper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { parseISO, format } from 'date-fns';
import dayjs from "dayjs";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import GoogleMapsAutoComplete from "../GoogleMapsAutoComplete";


const url = process.env.REACT_API_GOOGLE_MAPS;


const Geocoding = ({locationAdress, handleGeocoding}) => {
  const geocodingApiLoaded = useMapsLibrary("geocoding");
  const [geocondingervice, setGeocodingService] = useState();
  const [geocodingResult, setGeocodingResult] = useState();

  const [address, setAddress] = useState("Rebenweg 40, 8400 Winterthur");

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;



  useEffect(() => {
    if(!geocodingApiLoaded) return;
    setGeocodingService(new window.google.maps.Geocoder());
    setAddress(locationAdress)
  }, [geocodingApiLoaded, locationAdress]);


  useEffect(() => {
    if(!geocondingervice || !address || null) return;
      geocondingervice.geocode({address}, (results, status) => {
        if(results && status === "OK") {
          handleGeocoding(results[0])
        } else {
          return;
        }
      })
  }, [geocondingervice, address])


  return <></>
}

export const BasicFeatures = ({
  errorFields,
  stepIndicator,
  resetDateField,
  onDateChange,
  handleLocationCordinates
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext({
    mode: "onChange",
    defaultValues: {
      availability: "Nach Vereinbarung",
      customerType: 'tenant',
    },
  });
  const [selectedDate, setDateSelected] = useState(false);
  const [isAvailchoice, setAvailChoice] = useState(false);
  const addToQueue = useRequestQueue();
  const [cleared, setCleared] = useState(false);
  const selectedDateInput = watch("Date");
  const availability = watch("availability");
  const offerType = watch("offerType");
  const date = useStore((state) => state.date);
  const setDate = useStore((state) => state.setDate);
  const token = useStore((state) => state.jwtToken);
  const locationData = useStore((state) => state.locationData);
  const resetLocationData = useStore((state) => state.resetLocationData);
  const updateAdvertDetails = useStore((state) => state.updateAdvertDetails)

  const advertId = useStore(state => state.advertId);
  const customerType = useStore((state) => state.customerType);
  const setCustomerType = useStore((state) => state.setCustomerType);
  const [locationGps, setLocationGps] = useState({lat: 47.36667, lng: 8.55});


  const street = watch("streetNumber");
  const location = watch("location");
  const zipcode = watch("zipCode");

  const completeAdress = `${street}, ${location} ${zipcode}`;

  const locationRef = useRef(null);



  resetDateField(availability);

  useEffect(() => {
    
    setValue("availability", availability);
    setValue("zipCode", locationData.zipcode);
    updateAdvertDetails("zipCode", locationData.zipcode)
    setValue('location', locationData.location)
    updateAdvertDetails("location", locationData.location)
  }, [setValue, locationData]);

  useEffect(() => {
    errorFields(errors);
    stepIndicator({ step: 1, errors: Object.keys(errors) });
    if (selectedDateInput) {
      setDateSelected(true);
    } else {
      setDateSelected(false);
    }
  }, [selectedDateInput, errors]);

  const handleUpdate = useCallback((token, advertId, key, value) => {
    return updateAdvert(token, { id: advertId, key, value });
  }, []);



  const handleUpdateMulti = useCallback((token, advertId, updates) => {
    return updateAdvertMultiFields(token, { id: advertId, updates });
  }, []);

  const debouncedUpdateAdvertMulti = useCallback(
    debounce((token, advertId, key, value) => {
      handleUpdateMulti(token, advertId, key, value);
    }, 1000), // Adjust the delay as needed
    [handleUpdate]
  );
  
  const debouncedUpdateAdvert = useCallback(
    debounce((token, advertId, updates) => {
      handleUpdate(token, advertId, updates);
    }, 1000), // Adjust the delay as needed
    [handleUpdate]
  );


  

  const resultArray = resultArrayData;

  const handleAvailabilityChange = async(e, key) => {
    setValue("availability", e.target.value);
    setAvailChoice(true);
    updateAdvertDetails("availability", e.target.value);
    await updateAdvert(token, {id: advertId, key, value: e.target.value});

  };

  const handleUpdateAdvert = (key, value) => {
    updateAdvertDetails(key, value);
  };

  const handleDateChange = async(newDate) => {
    //Prevent Executing if no Date has set
    if (!newDate) {
      setDateSelected(false);
      setDate("");
      return;
    }

    const formattedDate = dayjs(newDate.$d);
    setDateSelected(true);
    setValue("availability", "");
    setAvailChoice(false);

    updateAdvertDetails("availability", formattedDate);
    await updateAdvert(token, {id: advertId, key: 'availability', value:formattedDate});
    
  };


  const handleRadioChange = async(event, key) => {
    const customerType = event.target.value;
    setCustomerType(customerType);
    await updateAdvert(token, {id: advertId, key, value:customerType});

  };


  const handleChangeOverAll = (key) => async (event) => {
    const value = event.target.value;

    updateAdvertDetails(key, value);

    console.log(value)
    if (key === "location") {
      debouncedUpdateAdvert(token, advertId, key, value);

    } else if (key === "zipCode" || key === "roomSpace") {
      debouncedUpdateAdvert(token, advertId, key, parseInt(value));
    } else {
      // Update the form value
      setValue(key, value);
      debouncedUpdateAdvert(token, advertId, key, value);
    }
  };


  const handleGeocoding = async (geoData) => {
    if (!geoData) return;
  
    const location = geoData.geometry.location;
    const lat = location.lat();
    const lng = location.lng();
  
    console.log(`Setting location - Latitude: ${lat}, Longitude: ${lng}`);
    
    // Update state with new coordinates
    setLocationGps({ lat, lng });
    handleLocationCordinates({ lat, lng });
  
    // Call debounced function once with both lat and lng
    debouncedUpdateAdvertMulti(token, advertId , {lat: lat.toString(), lng: lng.toString()})
  };
  
  const today = dayjs();
  const tomorrow = dayjs().add(10, 'day');


  return (
    <>
      <FormControl
        sx={{
          width: "100%",
          marginTop: 5,
          marginBottom: "40px",
        }}
      >
        <h2 className="text-lg sm:text-lg  font-bold text-left mb-2">
        Ich inseriere als:
        </h2>
        <Box sx={{ width: 300 }}>
      <FormLabel
        id="storage-label"
        sx={{
          mb: 2,
          fontWeight: 'xl',
          textTransform: 'uppercase',
          fontSize: 'xs',
          letterSpacing: '0.15rem',
        }}
      >
      </FormLabel>
      <RadioGroupJoy
        aria-labelledby="customer-label"
        value={customerType ?? 'tenant'}
        onChange={(event) => handleRadioChange(event, 'customerCategory')}
        size="lg"
        sx={{ gap: 1.5,  flexDirection: 'row',  [`& .${radioClasses.checked}`]: {
          [`& .${radioClasses.action}`]: {
            inset: -1,
            border: '3px solid',
            borderColor: 'primary.500',
          },
        },
        [`& .${radioClasses.radio}`]: {
          display: 'contents',
          '& > svg': {
            zIndex: 2,
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            bgcolor: 'background.surface',
            borderRadius: '50%',
          },
        }, }}
        
      >
        {customerSegment.map((value, index) => (
          <Sheet
            key={index}
            sx={{ p:2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 'md',
              boxShadow: 'sm',
              flexGrow: 1
            }}
          >
            <RadioJoy
            {...register("customerCategory", { required: true })}
              label={value.label}
              overlay
              disableIcon
              value={value.value}
              checkedIcon={<CheckCircleRoundedIcon />}
              helperText={
                Boolean(errors?.customerType) ? "Kategorie ist ein Pflichfeld" : ""
              }
              error={Boolean(errors?.customerType)}
              
              slotProps={{
                label: ({ checked }) => ({
                  sx: {
                    fontWeight: 'lg',
                    fontSize: 'md',
                    color: 'black',
                  },
                }),
                action: ({ checked }) => ({
                  sx: (theme) => ({
                    ...(checked && {
                      '--variant-borderWidth': '2px',
                      '&&': {
                        // && to increase the specificity to win the base :hover styles
                        borderColor: theme.vars.palette.primary[500],
                      },
                    }),
                  }),
                }),
              }}
            />
          </Sheet>
        ))}
      </RadioGroupJoy>
    </Box>
      </FormControl>
      <FormControl
        sx={{
          width: "100%",
          marginTop: 5,
          marginBottom: "40px",
        }}
      >
        <h2 className="text-lg sm:text-lg  font-bold text-left mb-2">
        Immobilie zu
        </h2>
        <RadioGroup
        defaultValue="rent"
          onChange={handleChangeOverAll("offerType")}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="rent"
            control={
              <Radio {...register("offerType")} />
            }
            label="Vermieten"
          />
          <FormControlLabel
            value="buy"
            control={
              <Radio {...register("offerType")} />
            }
            label="Verkaufen"
          />
        </RadioGroup>
      </FormControl>
      <Stack 
        sx={{marginBottom: 4}}
        direction={{ xs: "colum", md: "row" }}
        spacing={3}
        width="100%"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("objectType", {
            value: "Objekttyp",
            required: true,
          })}
          select
          defaultValue="none"
          onChange={handleChangeOverAll("objectType")}
          required
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.5%" },
          }}
          id="outlined-required"
          label="Kategorie"
        >
          {objectType.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Stack>
    <APIProvider apiKey='AIzaSyBzp--bZvp1fRNbmIpVBhe-MaRh4qxOUkg'>
      <Stack
        direction={{ xs: "colum", md: "row" }}
        spacing={3}
        width="100%"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          {...register("rooms", {
            value: "Anzahl Zimmer",
            required: true,
          })}
          defaultValue="2.5"
          select
          
          required  
          onChange={handleChangeOverAll('rooms')}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.5%" },
          }}
          id="outlined-required"
          label="Anzahl Zimmer"
          helperText="Bitte geben Anzahl zimmer an"
        >
          {resultArray.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "48.0%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputProps={{
            endAdornment: <span>m²</span>,
          }}
          {...register("roomSpace", { required: true })}
          id="outlined-required"
          type="number"
          label="Verfügbare Wohnfläche"
          onChange={handleChangeOverAll("roomSpace")}
          helperText={
            Boolean(errors?.roomspace)
              ? "Angaben zur Wohnfläche sind Pflicht"
              : ""
          }
          error={Boolean(errors?.roomspace)}
        >
        </TextField>

        <GoogleMapsAutoComplete />

        <TextField
          {...register("zipCode", { required: true })}
          className="no-autofill"
          required
           autoComplete='ViewCrunch'
          error={Boolean(errors?.zipcode)}
          sx={{
            width: "100%",
            "@media (min-width: 900px)": { width: "23%" },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          id="outlined-required"
          InputLabelProps={{ shrink: true}} 
          type="number"
          label="PLZ "
          helperText={Boolean(errors?.zipcode) ? "Das ist ein Pflichtfeld" : ""}
        ></TextField>
        <Tooltip title="Blur is not triggered" placement="top" arrow>
          <TextField
            {...register("location", { required: true })}
            className="no-autofill"
            autoComplete='ViewCrunch'
            inputRef={locationRef}
            error={Boolean(errors?.location)}
            InputLabelProps={{ shrink: true }}  
            required
            sx={{
              width: "100%",
              "@media (min-width: 900px)": { width: "22%" },
            }}
            id="outlined-required"
            label="Ort"
            helperText={Boolean(errors?.location) ?? ''}
          >
          </TextField>
        </Tooltip>
      </Stack>
      
        <Geocoding handleGeocoding={handleGeocoding}  locationAdress={completeAdress} />
        <Map
          center={locationGps}
          style={{width: '100%', height: '180px', marginTop: '20px', marginBottom: '10px'}}
          defaultCenter={locationGps}
          defaultZoom={16}
          gestureHandling={'greedy'}
          disableDefaultUI={true} 
          >

          <Marker position={locationGps}/>
        </Map>
      </APIProvider>
      <FormControl
        sx={{
          width: "100%",
          marginTop: 5,
        }}
      >
        <h2 className="text-lg sm:text-lg  font-bold text-left mb-2">
          Verfügbarkeit
        </h2>
        <RadioGroup
          value={availability ?? null}
          defaultValue="Nach Vereinbarung"
          onChange={(e) => handleAvailabilityChange(e, "availability")}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Sofort"
            control={
              <Radio {...register("availability")} disabled={selectedDate} />
            }
            label="Sofort"
          />
          <FormControlLabel
            value="Nach Vereinbarung"
            control={
              <Radio {...register("availability")} disabled={selectedDate} />
            }
            label="Nach Vereinbarung"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
            minDate={tomorrow}
                    slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => {
                      setCleared(true);
                      setDate("");
                    },
                  },
                }}
                {...(date || isAvailchoice
                  ? { defaultValue: dayjs(date) }
                  : {})}
                label="Datum auswählen"
                format="D/MM/YYYY"
                onChange={handleDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>
        </RadioGroup>
      </FormControl>
    </>
  );
};
